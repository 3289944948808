<script>
export default {
  name: "DocumentSearchRfi",
  created() {
    this.$router.push({
      name: "DocumentSearch",
      params: {
        module: "rfi",
      },
    });
  },
};
</script>
